.icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 100px;
    width: 100px;
    box-shadow:1px 2px 4px hsl(220deg 60% 50%);
  }
  
  .display_elements {
    /* visibility: hidden;
     */
    visibility: visible !important;
    /* display: none; */
    transition: 100 ease-in;
  }
  
  .display_elements1 {
    /* visibility: hidden; */
    visibility: visible !important;

    /* display: none; */
    opacity: 0.72;
    transition: 100 ease-in;
  }
  
  .Display_details:hover .display_elements {
    /* display: block !important; */
    visibility: visible !important;
  }
  
  .Display_details:hover .display_elements1 {
    /* display: block !important; */
    visibility: visible !important;
  }
  
  .Display_details:hover .icon{
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0,0);
  }
  
  